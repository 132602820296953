@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Roboto+Mono:wght@300;400;700&display=swap);
/* Imports */

/* Globals & General */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #1d1d1d;
  --mainWhite: #fff;
  --transparentWhite: rgba(255, 255, 255, 0.9);
  --btnGradient: linear-gradient(90deg, rgba(65, 83, 240, 0.3), #4153f0);
  --imageGradientWebkit: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(65, 83, 240, 0.5)),
    to(rgba(65, 83, 240, 0.5))
  );
  --imageGradient: linear-gradient(
    180deg,
    rgba(65, 83, 240, 0.5),
    rgba(65, 83, 240, 0.5)
  );
  --heroGradientWebkit: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fff),
      to(hsla(0, 0%, 100%, 0))
    ),
    -webkit-gradient(linear, left top, left bottom, from(rgba(65, 83, 240, 0.5)), to(rgba(65, 83, 240, 0.5))),
    -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.4)), to(hsla(0, 0%, 100%, 0.4)));
  --heroGradient: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0)),
    linear-gradient(180deg, rgba(65, 83, 240, 0.5), rgba(65, 83, 240, 0.5)),
    linear-gradient(180deg, hsla(0, 0%, 100%, 0.4), hsla(0, 0%, 100%, 0.4));
  --darkBlue: #4153f0;
  --mainBlack: #1d1d1d;
  --mainGrey: #ececec;
  --transparentGrey: #ecececf2;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 2px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

body {
  padding-top: 64px;
  color: #4153f0;
  color: var(--darkBlue);
  background: #fff;
  background: var(--mainWhite);
  font-family: "Roboto Mono", monospace;
  /* line-height: 1; */
}

h1 {
  font-size: 56px;
  line-height: 72px;
  font-weight: 400;
  font-family: "Playfair Display", serif;
  text-transform: lowercase;
}

h1::first-letter {
  text-transform: capitalize;
}

h2 {
  font-size: 32px;
  margin-bottom: 32px;
}

h3 {
  font-size: 24px;
}
h4 {
  font-size: 16px;
}

p {
  font-size: 16px;
}

.white-text {
  color: #fff;
  color: var(--mainWhite);
}

/* a:hover {
  opacity: 0.7;
}

.card-title:hover {
  opacity: 0.7;
} */

@media screen and (max-width: 992px) {
  h1 {
    font-size: 48px;
    line-height: 64px;
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
    line-height: 56px;
  }
  /* h2 {
    font-size: 24px;
  } */
}

/* Modifications */
.extra-margin-xlarge {
  margin-bottom: 96px;
}

.extra-margin-large {
  margin-bottom: 56px;
}
.extra-margin-medium {
  margin-bottom: 32px;
}
.extra-margin-small {
  margin-bottom: 16px;
}

.extra-margin-top {
  margin-top: 56px;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.bold {
  font-weight: 400;
}

.align {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left {
  text-align: left;
}

.center {
  text-align: center !important;
}
.right {
  text-align: right;
}

.half {
  width: 50% !important;
  display: inline-block;
}

@media only screen and (max-width: 1200px) {
  .half {
    width: 80% !important;
  }
}
@media only screen and (max-width: 768px) {
  .half {
    text-align: left !important;
    width: 100% !important;
  }
}

/* Buttons & Box (Offset) */
button,
.button {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.btn,
.box-wrap {
  text-decoration: none;
  position: relative;
  display: inline-block;
}
.btn.primary,
.box-wrap {
  margin: 0px 8px 8px 0px;
}
.box-wrap {
  width: 100%;
}

.btn-link,
.box-inner-wrap {
  position: relative;
  z-index: 1;
}

.btn-link.primary,
.box-inner-wrap {
  padding: 16px 32px;
  border: 2px solid #4153f0;
  border: 2px solid var(--darkBlue);
  background: #fff;
  background: var(--mainWhite);
}

.btn-link.primary.invert {
  background: #4153f0;
  background: var(--darkBlue);
  color: #ececec;
  color: var(--mainGrey);
}

.box-inner-wrap {
  padding: 56px;
}

.btn-link.secondary {
  padding: 8px 0px;
  border-bottom: 2px solid #4153f0;
  border-bottom: 2px solid var(--darkBlue);
}

.btn-link.secondary-white {
  padding: 8px 0px;
  border-bottom: 2px solid #fff;
  border-bottom: 2px solid var(--mainWhite);
}

.btn-link.secondary-white > .btn-text {
  color: #fff;
  color: var(--mainWhite);
}

.btn-text,
.clear-btn {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  letter-spacing: var(--mainSpacing);
  text-transform: uppercase;
  color: #4153f0;
  color: var(--darkBlue);
}

.btn-shadow,
.box-wrap-shadow {
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #4153f0;
  border: 2px solid var(--darkBlue);
  background-image: linear-gradient(90deg, rgba(65, 83, 240, 0.3), #4153f0);
  background-image: var(--btnGradient);
}

.clear-btn {
  text-decoration: none;
  color: #1d1d1d;
  color: var(--mainBlack);
}

.clear-btn.small {
  color: #1d1d1d;
  color: var(--mainBlack);
  font-size: 14px;
  margin-left: 8px;
}

@media only screen and (max-width: 992px) {
}

/* Navbar */
.sticky-nav {
  position: fixed;
  min-height: 64px;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 100%;
  z-index: 3;
  border-bottom: 2px solid #4153f0;
  border-bottom: 2px solid var(--darkBlue);
}

.nav-background {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  background-color: #fff;
  background-color: var(--mainWhite);
  width: 100%;
  height: 100%;
}

.nav-header {
  display: flex;
  justify-content: space-between;
  min-height: 64px;
  /* background-color: white; */
}

.logo-wrap {
  position: relative;
}

.logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 24px;
  color: #4153f0;
  color: var(--darkBlue);
}

.nav-links {
  height: 0;
  display: none;
  overflow: hidden;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  list-style-type: none;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 64px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto auto;
  grid-template-columns: auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav-links a {
  display: inline-block;
  text-decoration: none;
  padding: 16px 0px;
  color: #4153f0;
  color: var(--darkBlue);
  /* display: block; */
}
.nav-links a:hover {
  opacity: 0.7;
}

.show-nav {
  height: auto;
  width: 100%;
  padding: 32px;
}

@media screen and (min-width: 768px) {
  .nav-grid {
    padding: 0px 32px;
  }
}
@media screen and (max-width: 768px) {
  .nav-links {
    text-align: center;
    overflow: hidden;
    transition: all 0.3s linear;
    transition: var(--mainTransition);
    list-style-type: none;
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: auto;
    grid-column-gap: 64px;
    grid-row-gap: 16px;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    background-color: #fff;
    background-color: var(--mainWhite);
  }
  .nav-header {
    padding: 0px 32px;
  }
  .nav-grid {
    min-height: 64px;
    align-items: center;
  }
}

@media screen and (min-width: 992px) {
  .nav-grid {
    padding: 0px 56px;
  }
}

@media screen and (min-width: 768px) {
  .nav-grid {
    display: grid;
    max-width: 1440px;
    /* width: 100%; */
    /* padding: 0px 56px; */
    min-height: 64px;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
    grid-column-gap: 32px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr -webkit-max-content;
    -ms-grid-columns: 1fr max-content;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  .nav-btn {
    display: none;
  }

  .nav-links {
    height: auto;
    /* display: flex; */
  }
}

/* Footer */
.footer-wrap {
  width: 100%;
  background-color: #4153f0;
  background-color: var(--darkBlue);
}

/* Banner */
.banner {
  display: inline-block;
  color: #4153f0;
  color: var(--darkBlue);
  text-align: center;
  width: 100%;
  z-index: 1;
}

.banner.error {
  padding: 232px 0px 168px 0px;
}

@media only screen and (max-width: 992px) {
  .banner.error {
    padding: 144px 32px 80px 32px;
  }
}

.banner-text-wrap {
  width: 800px;
  margin-bottom: 72px;
  margin: 0 auto;
}

.banner p {
  font-size: 32px;
  margin: 72px 0px;
}

@media screen and (min-width: 576px) {
}

@media screen and (max-width: 992px) {
  .banner-text-wrap {
    width: 100%;
    margin-bottom: 72px;
    margin: 0 auto;
  }
}

/* Section  */
.section-wrap {
  position: relative;
  padding: 168px 56px;
  margin: auto;
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1440px;
  grid-auto-columns: 1fr;
  grid-column-gap: 96px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 0.75fr;
  grid-template-columns: 0.5fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-wrap.no-grid {
  max-width: 1440px;
  display: block;
  margin: 0 auto;
}

.section-wrap.less-padding,
.section-wrap.no-grid.less-padding,
.section-wrap.single-store {
  padding: 80px 56px;
}

.section-wrap.min-auto {
  -ms-grid-columns: minmax(auto, -webkit-min-content) auto;
  -ms-grid-columns: minmax(auto, min-content) auto;
  grid-template-columns: minmax(auto, -webkit-min-content) auto;
  grid-template-columns: minmax(auto, min-content) auto;
}

.section-wrap.reversed {
  -ms-grid-columns: 0.75fr 0.5fr;
  grid-template-columns: 0.75fr 0.5fr;
}

.section-wrap.reversed > .section-content-grid {
  grid-column-start: 1;
  grid-row-start: 1;
}

.section-wrap.vertical {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1440px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 96px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.section-description-wrap {
  text-align: center;
  position: relative;
}

.section-description {
  width: 100%;
  text-align: left;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 136px;
}

.section-content-grid {
  width: 100%;
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 56px;
  grid-row-gap: 56px;
  /* grid-row-gap: 96px; */
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-content-grid.three {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.section-content-grid.two {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

/* .section-content-grid.less-gap {
  grid-gap: 56px;
} */

.section-content-grid.less-row-gap {
  grid-row-gap: 8px;
}

.section-content-grid.min-gap {
  grid-gap: 32px;
}

.section-content-grid.set-width {
  width: 200px;
}

.info-inner-wrap {
  display: inline-block;
}

@media only screen and (max-width: 1200px) {
  .details-wrap > .btn.primary {
    display: none;
  }
  .section-wrap {
    grid-column-gap: 56px;
  }
  .section-wrap,
  .section-wrap.reversed {
    padding: 80px 32px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .section-wrap.footer {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: 56px;
  }
}

@media only screen and (max-width: 992px) {
  .section-content-grid.three {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .section-wrap.less-padding,
  .section-wrap.no-grid.less-padding,
  .section-wrap.single-store {
    padding: 80px 32px;
  }
}
.section-wrap.single-store {
  padding-top: 16px;
}

.section-content-grid.two.single-store {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (min-width: 768px) {
  .info-wrap:not(:last-child) {
    margin-bottom: 96px;
  }
}

@media only screen and (max-width: 768px) {
  .section-wrap {
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 96px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .section-wrap.reversed > .section-content-grid {
    position: relative;
  }
  .section-wrap.reversed {
    display: block;
  }
  .sticky {
    text-align: center;
  }

  .section-wrap.reversed > .section-description-wrap {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding: 56px;
  }
  .section-wrap.reversed > .align > .section-description {
    width: 80%;
    text-align: center;
    padding: 32px;
    background-color: #fff;
    background-color: var(--mainWhite);
    /* background-color: var(--transparentWhite); */
    border: 2px solid #4153f0;
    border: 2px solid var(--darkBlue);
    /* background-color: var(--transparentGrey); */
  }
  .section-content-grid.two {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .section-content-grid.three.single-store {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .section-wrap.single-store {
    grid-row-gap: 16px;
  }
  .info-wrap {
    margin-bottom: 56px;
  }
  /* .info-wrap:not(:last-child) {
    margin-bottom: 96px;
  } */
  .box-inner-wrap {
    padding: 32px;
  }
}
@media only screen and (max-width: 704px) {
  .section-content-grid.three {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .section-wrap.reversed {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 56px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .section-content-grid.three.single-store {
    grid-gap: 32px;
  }
  .section-wrap.reversed > .section-description-wrap {
    padding: 0px;
    position: relative;
  }
  .section-wrap.reversed > .align > .section-description {
    width: 100%;
    text-align: center;
    padding: 0px;
    background-color: transparent;
    border: none;
  }
  .map-img {
    width: 80% !important;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 464px) {
  .section-content-grid.three.single-store {
    grid-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .section-content-grid.two.single-store {
    grid-gap: 32px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

/* Cards */
.card-wrap {
  width: 100%;
}

.card-link {
  text-decoration: none;
  color: #4153f0;
  color: var(--darkBlue);
  white-space: nowrap;
}

.details-wrap {
  display: flex;
  padding: 32px 16px;
  justify-content: space-between;
  border-top: 2px solid #4153f0;
  border-top: 2px solid var(--darkBlue);
  border-bottom: 2px solid #4153f0;
  border-bottom: 2px solid var(--darkBlue);
}

.border-outline {
  border-top: 2px solid #4153f0;
  border-top: 2px solid var(--darkBlue);
  border-bottom: 2px solid #4153f0;
  border-bottom: 2px solid var(--darkBlue);
  padding: 32px 16px;
}

.card-title {
  font-family: "Playfair Display", sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-title-no-wrap {
  font-family: "Playfair Display", sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  text-transform: lowercase;
}

.card-title::first-letter,
.card-title-no-wrap::first-letter {
  text-transform: capitalize;
}

@media screen and (min-width: 768px) {
  .card-title {
    width: calc((100vw - 192px) / 2);
  }
}

@media screen and (min-width: 992px) {
  .card-title {
    width: calc((100vw - 232px) / 2);
  }
}

@media screen and (min-width: 1200px) {
  .card-title {
    width: calc((100vw - 596px) / 2);
  }
}

@media screen and (min-width: 1440px) {
  .card-title {
    width: 100%;
  }
}

@media screen and (max-width: 464px) {
  .card-title {
    width: calc(100vw - 96px);
  }
}

/* Various Featured */
.map-img {
  width: 100%;
}

/* Tags */
.tag-outer-wrap {
  margin-bottom: 16px;
  margin-top: 32px;
}

.tag-wrap {
  text-decoration: none;
  padding: 8px;
  background-color: #ececec;
  background-color: var(--mainGrey);
  color: #4153f0;
  color: var(--darkBlue);
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-block;
}

.tag-small,
.close-tag,
label {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  font-weight: 300;
  text-transform: uppercase;
  display: inline-block;
}

.tag-capitalize {
  text-decoration: none;
  text-transform: lowercase;
  color: #4153f0;
  color: var(--darkBlue);
  font-size: 14px;
}
.tag-capitalize.cut {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag-capitalize.cut > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag-capitalize.bold {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-tag {
  padding-right: 8px;
  font-size: 16px;
  text-transform: lowercase;
  font-weight: 400;
  line-height: normal;
}

.tag-wrap span {
  display: inline-block;
}

.tag-capitalize::first-letter {
  text-transform: capitalize;
}

.tag-wrap:first-child {
  margin-bottom: 16px;
  margin-top: 32px;
}

/* Alphabetical List */
.letters-wrap,
.numbers-wrap {
  display: block;
}

.letter,
.number {
  margin: 8px;
  text-decoration: none;
  display: inline-block;
  color: #4153f0;
  color: var(--darkBlue);
}

.number.active {
  color: #4153f0;
  color: var(--darkBlue);
  border-bottom: 2px solid #4153f0;
  border-bottom: 2px solid var(--darkBlue);
}

@media screen and (min-width: 992px) {
}

/*  Filter / Form */
.form-outer-wrap {
  margin-top: 56px;
}

.form-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-row-gap: 56px;
  grid-column-gap: 56px;
}

.form-wrap.less-row-gap {
  grid-row-gap: 8px;
}

.designers-wrap {
  grid-column: 1 / -1;
}

.designers-wrap {
  border-bottom: 2px solid #cfcfcf;
  border-bottom: 2px solid var(--darkGrey);
  grid-column: 1 / -1;
}

.designers-scroll {
  height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.form-group {
  text-transform: capitalize;
}

input[type="text"],
select {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #cfcfcf;
  border: 2px solid var(--darkGrey);
  color: #1d1d1d;
  color: var(--mainBlack);
  padding: 8px 8px 8px 16px;
  font-family: "Roboto Mono", monospace !important;
  font-size: 14px !important;
  border-radius: 0px;
}

select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.checkbox-text {
  font-size: 14px;
  text-transform: capitalize;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #1d1d1d;
  color: var(--mainBlack);
}

.size-inputs {
  display: flex;
}

.checkbox {
  display: flex;
}

.checkbox label {
  display: inline-block;
  line-height: 0.8;
  margin-left: 8px;
}

.clear-div {
  height: 48px;
}

@media screen and (min-width: 1440px) {
  .form-wrap.single-store {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1200px) {
  /* .form-wrap.single-store {
    -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  } */
}

